* {
	font-family: "Albert Sans", sans-serif;
	font-optical-sizing: auto;
	font-style: normal;
}

/* Ensure the body and html have no horizontal overflow */
html,
body {
	max-width: 100%;
	overflow-x: hidden;
	margin: 0;
	padding: 0;
	scroll-behavior: smooth;
	font-family: "Poppins", sans-serif;
}

.App {
	text-align: center;
	font-weight: 400;
	font-style: normal;
	background-color: #1c1f25;
	color: aliceblue;
}

section {
	width: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	justify-content: flex-start; /* Align content to the top */
	align-items: center;
	padding: 50px 20px;
	min-height: 100vh;
	z-index: 1;
	outline: black;
	overflow: hidden; /* Prevent content overflow */
}

div h2 {
	font-size: 3.5rem;
	font-weight: 900;
	margin: 0;
}

div h3 {
	font-size: 2.25rem;
	font-weight: 800;
	margin: 0;
	padding-bottom: 25px;
}

div p {
	font-size: 1.75rem;
	font-weight: 200;
	margin: 0;
}

#home {
	position: relative;
	justify-content: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-color: black;
}

#home-background-video {
	position: absolute;
	height: 115%;
	width: 100vw;
	object-fit: cover;
	z-index: -1;
	filter: blur(5px) brightness(80%);
	padding-bottom: 25px;
}

.home-logo {
	height: 16em;
	width: auto; 
	padding-bottom: 20px;
}

#home-down-arrow {
	position: absolute;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	color: #ffffff8c;
	animation: bounce 1.5s infinite;
	cursor: pointer;
	transition: color 0.15s ease-in-out opacity 0.5s ease; /* Smooth transition for opacity */
}

#home-down-arrow:hover {
	color: white;
}

@keyframes bounce {
	0%,
	100% {
		transform: translateX(-50%) translateY(0);
	}
	50% {
		transform: translateX(-50%) translateY(-15px);
	}
}

#home-video-link {
	position: absolute;
	bottom: 20px;
	right: 2%;
	margin: 20px;
}

#home-video-link a {
	color: #ffffff8c;
	text-decoration: none;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out;
}

#home-video-link a:hover {
	color: white;
	text-decoration: none;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out;
}

.App-header img {
	margin-bottom: 20px;
}

/* Additional sections */
#games,
#team,
#contact {
	background-image: none; /* No background image for these sections */
	filter: none; /* Reset blur for other sections */
}

/* Container for the buttons */
.social-buttons-container {
	display: flex;
	justify-content: center; /* Center buttons horizontally */
	gap: 20px; /* Space between buttons */
	flex-wrap: wrap; /* Ensure buttons wrap if the screen size is small */
	margin-top: 20px; /* Space above buttons */
}

.social-icons-container {
	display: flex;
	gap: 40px;
	justify-content: left;
}

.social-icons-container a {
	color: white;
	font-size: 2.5rem;
	text-decoration: none;
	transition: color 0.3s ease; /* Smooth transition for color change */
}

.social-icons-container a:hover {
	color: #ffffffbb;
}

.section-header {
	width: 100%; /* Make it take up all horizontal space */
	display: flex;
	justify-content: flex-start; /* Align content to the left */
	align-items: center; /* Align vertically */
	padding-left: 10vw; /* Add padding to the left */
	padding-right: 10vw; /* Add padding to the left */
	margin-top: 6vh;
	font-weight: 900;
	text-align: left;
	gap: 20px;
}

.section-header-icon {
	height: 4em; 
	width: auto;
}

.section-content {
	text-align: left;
	padding-top: 75px;
	padding-left: 17.5vw;
	padding-right: 17.5vw;

	text-align: justify;
}

.section-separator {
	background-color: #252d3a; /* Light gray */
	height: 2px;
	margin: 0 auto;
	margin-top: 50px;
	margin-bottom: 50px;
	width: 100%;
}

.game-logo {
	height: 12em;
	width: auto; 
	padding-left: 40px;
}

.game-description {
	font-size: 1.3rem;
	color: #BDC7D7;
	font-weight: 250;
	padding-right: 10px;
}

.team-grid {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 20px;
	padding: 20px;
}

/* Mobile/Vertical aspect ratio styling */
@media (aspect-ratio < 1 / 1) {
	#header {
		display: none !important;
	}

	.home-logo {
		height: auto;
		width: 80vw;
	}

	.home-subtitle {
		font-size: 1.5rem;
	}

	#home-video-link {
		display: none;
	}

	.section-header {
		padding-left: 5vw;
		padding-right: 0vw;
		gap: 10px;
	}

	.section-header h2 {
		font-size: 2.25rem;
	}

	.section-content {
		padding-left: 5vw;
		padding-right: 5vw;
	}

	#section-header-games {
		display: block;
	}

	.game-logo {
		padding-left: 0;
		padding-bottom: 2em;
	}

	.game-description {
		font-size: 1rem;
	}

	#game-socials {
		font-size: 0.6em;
		gap: 10px;
	}

	#company h3 {
		font-size: 2rem;
	}

	#company p {
		font-size: 0.9rem;
	}

	.team-main-member {
		padding-top: 4em;
		padding-bottom: 2em;
	}

	.team-grid {
		grid-template-columns: repeat(2, 1fr);
		gap: 20px;
		padding: 20px;
	}
}