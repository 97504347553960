.contact-form {
	text-align: left;
	width: 75vw;
	max-width: 64em;
	padding: 75px;
	box-sizing: border-box;
}

.contact-form label {
	display: block;
	margin-bottom: 1em;
     font-weight: bold;
	color: white;
	font-size: 1.25em;
}

.contact-form input,
.contact-form textarea {
	width: 100%;
	padding: 0.75em 0.6em;
	margin-top: 0.5em;
	margin-bottom: 1em;
	border: 1px solid #ccc;
	border-radius: 8px;
	font-size: 1.05em;
	color: #bdc7d7;
	background-color: #22262d;
	border: none;
	resize: none;
	box-sizing: border-box;
}

.contact-form input:focus,
.contact-form textarea:focus {
	outline: none;
	border: 1px solid #474d57;
}

.contact-form .error {
	color: #ff4d4d;
	font-size: 0.9em;
	margin-top: -10px;
	margin-bottom: 10px;
	display: block;
}

.contact-form button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 10px 20px;
	background-color: white;
	color: black;
	border: none;
	border-radius: 15px;
	font-size: 1.2em;
	font-weight: bold;
	cursor: pointer;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
	transition: all 0.3s ease;
}

.contact-form button:hover {
	transform: scale(1.05);
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
}

.send-icon {
	font-size: 1.5em;
	margin-right: 10px;
	display: flex;
	align-items: center;
}

.button-wrapper {
	text-align: right;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.info-text {
	color: #707C8D;
	font-size: 1em;
	max-width: 25em;
	text-align: left;
}

/* Mobile/Vertical aspect ratio styling */
@media (aspect-ratio < 1 / 1) {
	.contact-form {
		width: 90vw;
		max-width: 90vw;
		padding: 75px 25px;
	}

	.info-text {
		color: #707C8D;
		font-size: 0.9em;
		max-width: 10em;
		text-align: left;
	}
}
