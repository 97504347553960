.footer {
     text-align: center;
     padding: 20px 10px;
 }
 
 .footer-top-line {
     background-color: #252D3A; /* Light gray */
     height: 2px;
     margin: 0 auto;
     margin-bottom: 25px;
     width: calc(100% - 12vh);
 }
 
 .footer-content {
     display: flex;
     flex-direction: column;
     align-items: center;
     gap: 15px;
 }
 
 .footer-categories {
     display: flex;
     justify-content: center;
     gap: 40px;
 }
 
 .footer-category {
     text-align: left;
 }
 
 .footer-category h5 {
     color: white;
     font-weight: bold;
     margin-bottom: 10px;
 }
 
 .footer-category ul {
     list-style: none;
     padding: 0;
     margin: 0;
 }
 
 .footer-category ul li {
     margin: 5px 0;
 }
 
 .footer-category ul li a {
     color: #BDC7D7;
     text-decoration: none;
     font-size: 0.9rem;
     transition: color 0.3s ease; /* Smooth transition for color change */
 }
 
 .footer-category ul li a:hover {
     color: #bdc7d7c0;
 }
 
 .disabled-link {
     color: #bdc7d796;
     font-size: 0.9rem;
     cursor: default;
     text-decoration: none;
     pointer-events: none;
 }
 
 .footer-icons {
     display: flex;
     gap: 20px;
     justify-content: center;
 }
 
 .footer-icons a {
     color: white;
     font-size: 1.5rem;
     text-decoration: none;
     transition: color 0.3s ease; /* Smooth transition for color change */
 }
 
 .footer-icons a:hover {
     color: #ffffffbb;
 }

 .footer-copyright {
     color: #707C8D;
     font-size: 0.85rem;
 }