.studio-icon {
    height: 1.5em;
    width: auto; 
    margin-right: 10px;
}

.studio-top {
    height: 1.5em;
    width: auto; 
    margin-right: 5px;
}

/* Custom navbar styling */
.custom-navbar {
    position: relative;
    padding: 10px 20px;
    background-color: rgba(25, 25, 25, 0.4);
}

/* Remove the gradient background */
.custom-navbar::before {
    content: '';
    position: absolute;
    top: -10vh;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #0f1114;
    filter: blur(16px);
    z-index: -1;
}

.navbar-title {
    font-weight: bolder;
    color: aliceblue !important;
}

.nav-link {
    color: aliceblue !important;
    display: flex;
    align-items: center;
    gap: 5px;
}

.nav-link.active {
    font-weight: bold;
    color: aliceblue !important;
    border-bottom: 2px solid aliceblue;
}

/* Mobile/Vertical aspect ratio styling */
@media (aspect-ratio < 1 / 1) {
    .custom-navbar {
        padding: 2.5px 2.5px;
    }

    .studio-icon {
        height: 0.75em;
        width: auto; 
        margin-right: 10px;
    }
    
    .studio-top {
        height: 0.75em;
        width: auto; 
        margin-right: 5px;
    }

    #links {
        display: none !important;
    }
}