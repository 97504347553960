a {
    text-decoration: none!important;
    color: inherit; /* Inherit color from parent */
 }

.social-media-button {
     display: flex;
     align-items: center;
     justify-content: center; /* Center items horizontally */
     padding: 10px 20px;
     border: none;
     border-radius: 15px; /* Rounded corners */
     color: white;
     font-size: 1.2em;
     font-weight: bold;
     box-shadow: 0 2px 16px rgba(0, 0, 0, 0.25); /* Background shadow */
     transition: all 0.3s ease; /* Smooth transition for hover effects */
     cursor: pointer;
 }
 
 .social-media-button:hover {
     transform: scale(1.05); /* Slightly scale the button on hover */
     box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5); /* Increased shadow on hover */
 }
 
 .social-media-icon {
     font-size: 1.5em;
     margin-right: 10px; /* Space between icon and name */
     display: flex;
     align-items: center; /* Vertically center the icon */
 }
 
 .social-media-name {
     font-size: 1.1em;
     display: flex;
     align-items: center; /* Vertically center the text */
 }
 