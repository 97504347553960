.team-member-card {
	text-align: left;
	display: inline-block;
	width: 100%;
	max-width: 16em; /* Ensure a maximum size for each card */
	margin: 0.5em;
	box-sizing: border-box;
 }
 
 .profile-icon {
	width: 100%; /* Take up the full width of the card */
	height: auto; /* Maintain aspect ratio */
	aspect-ratio: 4 / 3; /* Ensure 4:3 aspect ratio */
	border-radius: 5%;
	object-fit: cover; /* Ensures aspect ratio is maintained and fills the container */
	object-position: center; /* Centers the image within the container */
 }
 
 .team-member-name {
	font-size: 1.6em;
	padding-top: 0.4em;
	margin-bottom: -0.25rem;
	font-weight: 650;
 }
 
 .team-member-role {
	font-size: 1.2em;
	color: white;
	font-weight: 250;
 }
 
 .social-icons {
	display: flex;
	gap: 10px;
	justify-content: left;
 }
 
 .social-icons a {
	color: #8490a4;
	font-size: 1.5rem;
	text-decoration: none;
	transition: color 0.3s ease; /* Smooth transition for color change */
 }
 
 .social-icons a:hover {
	color: #8490a483;
 }
 
 /* Mobile/Vertical aspect ratio styling */
 @media (aspect-ratio < 1 / 1) {
	.team-member-card {
	    max-width: 90%; /* Adjust card width for smaller screens */
	    margin: 1em auto; /* Center cards horizontally */
	}
 
	.profile-icon {
	    aspect-ratio: 4 / 3; /* Ensure consistent aspect ratio */
	}
 
	.team-member-name {
	    font-size: 1.4em; /* Slightly smaller font for better fit */
	}
 
	.team-member-role {
	    font-size: 1em; /* Adjust role text size */
	}
 
	.social-icons {
	    gap: 5px; /* Reduce gap between icons */
	}
 
	.social-icons a {
	    font-size: 1.5rem; /* Adjust icon size for smaller screens */
	}
 }