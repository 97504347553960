.carousel-container {
	display: flex;
	align-items: center;
	gap: 1rem;
	flex-wrap: wrap;
}

.main-image {
	flex: 3;
	position: relative;
	width: 60vw;
	height: 33.75vw;
	overflow: hidden;
	border-radius: 8px;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.main-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.thumbnail-container {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	flex: 1;
	width: 15vw;
}

.thumbnail-container img {
	width: 100%;
	aspect-ratio: 16 / 9;
	object-fit: cover;
	cursor: pointer;
	border-radius: 8px;
	transition: transform 0.3s, opacity 0.3s;
}

.thumbnail-container img:hover {
	transform: scale(1.01);
}

.thumbnail.active {
	border: 5px solid rgb(255, 255, 255, 0.2);
	opacity: 1;
}

.thumbnail:not(.active) {
	opacity: 0.25;
}
   
.thumbnail-container img:not(.active):hover {
	opacity: 1;
}

@media (max-width: 768px) {
	.carousel-container {
		flex-direction: column;
		align-items: center;
	}

	.main-image {
		width: 80vw;
		height: 45vw;
	}

	.thumbnail-container {
		flex-direction: row;
		width: auto;
	}

	.thumbnail-container img {
		width: 20vw;
	}
}

.progress-bar {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 5px;
	background-color: rgba(255, 255, 255, 0.3);
	overflow: hidden;
	border-radius: 0 0 8px 8px;
}

/* Progress fill */
.progress-fill {
	height: 100%;
	background-color: rgba(255, 255, 255, 0.3);
	transition: width 0.1s linear;
}
